// Fonctions de validation
export const validators = {
  isValidNumber: (value) => {
    return !isNaN(Number(value)) && value.trim() !== '';
  },

  isValidSurface: (value) => {
    const number = Number(value);
    return !isNaN(number) && number >= 0;
  },

  isValidText: (value) => {
    return value.trim().length > 0;
  }
};

// Fonctions utilitaires pour manipuler les données
export const dataUtils = {
  // Initialiser les données d'un élément
  initializeElementData: (elementConfig) => {
    if (!elementConfig) return null;

    if (elementConfig.type === 'text') {
      return {
        type: 'text',
        content: elementConfig.content
      };
    }

    const quantities = elementConfig.quantities ? 
      Array(elementConfig.quantities.rows).fill(null).map((_, rowIndex) => ({
        rowIndex,
        values: elementConfig.quantities.headers.map((_, colIndex) => ({
          columnIndex: colIndex,
          value: ''
        }))
      })) : [];

    const informations = elementConfig.informations ?
      elementConfig.informations.rows.map((text, rowIndex) => ({
        rowIndex,
        text,
        value: ''
      })) : [];

    return { quantities, informations };
  },

  // Obtenir les valeurs formatées d'un élément
  getFormattedElementData: (elementData) => {
    if (!elementData) return null;

    return {
      quantities: elementData.quantities?.reduce((acc, row) => {
        row.values.forEach(val => {
          acc[`col${val.columnIndex}`] = val.value;
        });
        return acc;
      }, {}),
      informations: elementData.informations?.reduce((acc, row) => {
        acc[row.rowIndex] = row.value;
        return acc;
      }, {})
    };
  },

  // Mettre à jour une valeur
  updateElementValue: (elementData, tableType, rowIndex, columnIndex, value) => {
    if (!elementData) return null;

    const newData = { ...elementData };

    if (tableType === 'quantities') {
      if (!newData.quantities?.[rowIndex]?.values?.[columnIndex]) return elementData;
      newData.quantities[rowIndex].values[columnIndex].value = value;
    } else if (tableType === 'informations') {
      if (!newData.informations?.[rowIndex]) return elementData;
      newData.informations[rowIndex].value = value;
    }

    return newData;
  },

  // Vérifier si l'élément est complet
  isElementComplete: (elementData) => {
    if (!elementData) return false;

    if (elementData.type === 'text') return true;

    const quantitiesComplete = elementData.quantities?.every(row =>
      row.values.every(val => validators.isValidNumber(val.value))
    ) ?? true;

    const informationsComplete = elementData.informations?.every(row =>
      validators.isValidText(row.value)
    ) ?? true;

    return quantitiesComplete && informationsComplete;
  }
};