import React from "react";
import { connect } from "react-redux";
import Header from '../Header/Header';
import DocMenu from '../DocMenu';
import NumDoss from '../NumDoss';
import GenPdf from '../GenPdf';
import InformationDialog from '../InformationDialog';
import YesNoDialog from '../YesNoDialog';
import FactureReaData from './FactureRea/FactureReaData';
import FactureReaDetail from './FactureRea/FactureReaDetail';
import FactureStatus from './FactureStatus';
import Button from "@material-ui/core/Button";
import { getAllReaFacs, getContact, getDossier, getFactureRea, genPDF, getPDF, getEch, titlesRealisation } from './Utils';
import { fetchFac, fetchFacByDoss, changeFacIndexValue } from '../../redux/actions/facActions';
import { fetchContrat } from '../../redux/actions/contratActions';
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import "../../Styles/Factures/FactureRea.scss";

class FactureRea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is26Percent: true,
      facIndex: 1,
      sortedFac: [],
      devisrea: {},
      dossier: {},
      mo: {},
      file: null,
      dataDetail: true,
      puHt: 0,
      totalht: 0,
      nbFacReaCreated: 0,
      quantity: Array(9).fill(0),
      amountHt: Array(9).fill(0),
      dateFac: null,
      datecomptable: null,
      openDialog: false,
      openDialogCreate: false,
      openDialogCreateLimite: false,
      pdf: false,
      bank: 'bred',
      isHidden: true,
      disableButton: false,
      tva: 20,
      bdcValue: "",
    };

    this.loadData = this.loadData.bind(this);
    this.generateArrayData = this.generateArrayData.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { dossierValue } = this.props;
    if (dossierValue) {
      this.props.dispatch(fetchFacByDoss(dossierValue))
      this.loadData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dossierValue, contratValues, factures, echeances, facturesbydoss } = this.props;

    if (factures.length === 0) {
      this.props.dispatch(fetchFac('realisation'))
    }

    if (dossierValue !== prevProps.dossierValue ||
      factures !== prevProps.factures ||
      echeances !== prevProps.echeances ||
      this.state.facIndex !== prevState.facIndex) {
      this.props.dispatch(fetchFacByDoss(dossierValue))
      this.loadData();
    }

    if (contratValues && contratValues !== prevProps.contratValues) {
      const { payment_method, bank } = contratValues;
      this.setState({
        is26Percent: payment_method == 26,
        bank
      });
    }
    if (this.props.facindex !== prevProps.facindex) {
      this.setState({
        facIndex: this.props.facindex != 0 ? this.props.facindex : 1
      })
    }
    if (facturesbydoss !== prevProps.facturesbydoss) {
      this.loadData()
    }
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    const { factures, dossierValue, dossier, contact, echeances, contratValues, facturesbydoss } = this.props

    const nbFacReaCreated = facturesbydoss.filter(element => element.numclient.includes("CX")).length
    this.props.dispatch(fetchContrat(dossierValue))
    const devisrea = this.recuperDevisrea(dossierValue)
    const sortedFac = getFactureRea(getAllReaFacs(factures, dossierValue), `R${this.state.facIndex} `)
    const data = facturesbydoss.filter(item => item.numclient == sortedFac.numclient)
    let echeance = getEch(echeances, dossierValue)
    echeance = echeance !== null ? JSON.parse(echeance.dates) : null

    this.setState({
      nbFacReaCreated,
      sortedFac,
      dossier: getDossier(dossier, dossierValue),
      mo: getContact(dossier, contact, dossierValue),
      puHt: data.length ? data[0].pu_ht : devisrea?.tauxh,
      devisrea,
      totalht: data.length ? data[0].total_ht : 0,
      quantity: data.length ? JSON.parse(data[0].quantity) : Array(9).fill(0),
      amountHt: data.length ? JSON.parse(data[0].amount_ht) : Array(9).fill(0),
      datecomptable: data.length ? data[0].date_fac.substring(0, 10) : null,
      dateFac: echeance == null ?
        contratValues && (Object.keys(contratValues).length > 0) ?
          this.formatDate(contratValues.datedem.substring(0, 10)) :
          null :
        echeance[this.state.facIndex - 1]?.dateech,
      tva: data.length ? JSON.parse(data[0].tva) : 20,
      isHidden: data.length ? data[0].validation == 1 ? false : true : true,
      bdcValue: data.length ? data[0].bdc_facture : "",
      file: null
    })
  }

  recuperDevisrea(ndossier) {
    const devisreaNdossier = this.props.devisrea.filter(item => item.ndossier == ndossier && item.validation == 1);
    devisreaNdossier.sort((a, b) => b.nprop - a.nprop);
    const elementRecherche = devisreaNdossier[0];
    return elementRecherche;
  }

  handleChange(event, index) {
    const value = event.target.value;
    if (!/^[0-9.]*$/.test(value)) return
    const elementHt = this.state.puHt * value
    let quantity = [...this.state.quantity]
    let amountHt = [...this.state.amountHt]
    quantity[this.checkIndex(index)] = value
    amountHt[this.checkIndex(index)] = parseFloat(elementHt.toFixed(2))
    const totalht = parseFloat(amountHt.reduce((total, element) => total + element, 0).toFixed(2))
    this.setState({ quantity, amountHt, totalht });
  }

  handleChangeTva(value) {
    this.setState({ tva: value });
  }

  handleChangeDetailValue(event) {
    const { name, value } = event.target
    if (name == 'datefacture') {
      this.setState({ dateFac: value })
    } else this.setState({ [name]: value });
  }

  checkIndex(index) {
    if (index < 4) {
      return index - 1;
    } else if (index < 8) {
      return index - 2;
    } else return index - 3
  }

  formatDate(eventValue) {
    const intervalInMonths = (this.state.is26Percent ? 2 : 1) * this.state.facIndex
    let date = new Date(eventValue)
    date.setMonth(date.getMonth() + intervalInMonths)
    return `${date.toISOString().slice(0, 8)}02`
  }

  generateArrayData(quantity, amountHt) {
    const { puHt } = this.state
    const result = [];
    for (let i = 0; i < quantity.length; i++) {
      result.push({ "title": titlesRealisation[i], "quantity": quantity[i] === "" ? 0 : quantity[i], "puht": puHt.toFixed(2), "amountht": amountHt[i], "tva": 1 });
    }
    return result;
  }

  transformText(inputText) {
    return inputText.replace(/(\d)(\d)(\d)/, '$1-$2-$3');
  }

  sumQuantity(array) {
    const arrayFormated = array.map(item => parseFloat(item))
    return arrayFormated.reduce((total, currentValue) => total + currentValue, 0);
  }

  handleCreateFac = async () => {
    try {
      const { dossierValue, factures, devisrea, echeances } = this.props;
      const sortedFactures = factures.filter((item) => item.ndossier === dossierValue);
      const indexFacture = sortedFactures.length + 1;
      const echeance = getEch(echeances, dossierValue)
      const lastDevisRea = (devisrea.filter((item) => item.ndossier == dossierValue).sort((a, b) => b.nprop - a.nprop))[0]
      const dureeMax = (lastDevisRea.duree % 2 === 0 ? lastDevisRea.duree : lastDevisRea.duree + 1) / (echeance.payment_method == 26 ? 2 : 1)

      if (indexFacture > dureeMax) {
        this.handleCloseCreate();
        this.setState({ openDialogCreateLimite: true })
        return
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        body: JSON.stringify({
          ndossier: dossierValue,
          indexFacture
        })
      };

      const response = await fetch("/api/facture/createEcheanceRea", requestOptions);
      if (!response.ok) {
        throw new Error("La requête a échoué.");
      }

      await response.json();
      await this.handleCloseCreate();
      await Promise.all([
        this.props.dispatch(fetchFac("realisation")),
        this.props.dispatch(changeFacIndexValue(indexFacture))
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  handleSaveClick = async () => {
    const { dateFac, bdcValue, is26Percent, sortedFac, totalht, tva, quantity, amountHt, mo, datecomptable, puHt } = this.state;
    if (!datecomptable) {
      this.setState({ openDialog: true });
      return;
    }
    this.setState({ disableButton: true });

    const jsonQantity = JSON.stringify(quantity.map(item => parseFloat(item)));
    const jsonAmoutHt = JSON.stringify(amountHt);
    const bank = this.state.bank;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        numclient: sortedFac.numclient,
        nfacture: sortedFac.new_nfac,
        date_ech: dateFac,
        date_fac: datecomptable,
        mo,
        quantity: jsonQantity,
        amount_ht: jsonAmoutHt,
        total_ht: totalht,
        tva,
        pu_ht: puHt,
        payment_method: is26Percent ? 26 : 34,
        first_slice: 1,
        bank,
        facture_type: 'rea',
        bdc_facture: bdcValue
      })
    };

    try {
      const response = await fetch("/api/facture/savefacture", requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (err) {
      console.error('Fetch error:', err);
    }

    await this.props.dispatch(fetchFacByDoss(this.props.dossierValue));
    this.setState({ disableButton: false });
  }

  handleGenClick = async () => {
    const { sortedFac, bdcValue, totalht, tva, quantity, amountHt, dossier, datecomptable } = this.state
    if (!datecomptable) {
      this.setState({ openDialog: true })
      return
    }
    this.setState({ file: null, pdf: true, disableButton: true })
    const arraydata = this.generateArrayData(quantity, amountHt)
    const amouttva = totalht * tva / 100
    const totalttc = totalht + amouttva
    const totalhtFixed = totalht.toFixed(2)
    const nfacvalue = sortedFac.new_nfac.replace(' ', '/')
    const customerCode = this.transformText(sortedFac.numclient)
    const sumquantity = this.sumQuantity(quantity)
    const bank_name = this.state.bank
    let mo = { ...this.state.mo }
    mo.name = mo.sexe == 'homme' ? `Monsieur ${mo.prenom} ${mo.nom}` : `Madame ${mo.prenom} ${mo.nom}`
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        filetitle: customerCode.replace(/\//g, ' '),
        customercode: customerCode,
        dossier,
        arraydata,
        tva,
        amouttva: amouttva.toFixed(2),
        totalht: totalhtFixed,
        totalttc: totalttc.toFixed(2),
        sumquantity,
        bank_name,
        nfacvalue,
        mo,
        datefac: this.state.dateFac,
        datecompta: datecomptable,
        facture_type: 'rea',
        bdc_facture: bdcValue
      })
    };

    let blob = await genPDF(requestOptions);
    if (blob) {
      await this.handleSaveClick();
      await this.setState({ file: blob, disableButton: false });
    }
  }

  handleShowPdf = async () => {
    const { sortedFac } = this.state
    let customerCode = this.transformText(sortedFac.numclient)
    customerCode = customerCode.replace(/\//g, ' ')
    await this.setState({ file: null, pdf: true, disableButton: true });
    let response = await getPDF(this.props.dossierValue, customerCode, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, pdf: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ pdf: false });
    }
    await this.setState({ disableButton: false });

  };

  handleGetPdfFile = async () => {
    const { sortedFac } = this.state
    let customerCode = this.transformText(sortedFac.numclient)
    customerCode = customerCode.replace(/\//g, ' ')
    this.setState({ file: null });

    let response = await getPDF(this.props.dossierValue, customerCode, 'pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    const { sortedFac } = this.state
    let customerCode = this.transformText(sortedFac.numclient)
    customerCode = customerCode.replace(/\//g, ' ')
    this.setState({ file: null });

    let response = await getPDF(this.props.dossierValue, customerCode, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleClose = () => {
    this.setState({ openDialog: false, dataDetail: true });
  };

  handleOpenCreate = () => {
    this.setState({ openDialogCreate: true });
  };

  handleCloseCreate = () => {
    this.setState({ openDialogCreate: false });
  };

  handleOpenCreateLimite = () => {
    this.setState({ openDialogCreateLimite: true });
  };

  handleCloseCreateLimite = () => {
    this.setState({ openDialogCreateLimite: false });
  };

  closeDoc = () => {
    this.setState({ pdf: false });
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Facture Réalisation R${this.state.facIndex}`))
  }

  render() {
    const {
      is26Percent,
      dataDetail,
      sortedFac,
      puHt,
      totalht,
      quantity,
      amountHt,
      tva,
      dossier,
      mo,
      devisrea,
      dateFac,
      datecomptable,
      openDialog,
      openDialogCreate,
      openDialogCreateLimite,
      file,
      isHidden,
      pdf,
      disableButton,
      bdcValue
    } = this.state
    const { factures, dossierValue } = this.props
    const sortedFactures = factures.filter((item) => item.ndossier === dossierValue);

    return (
      <div className="facture-con">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Facture Realisation</h1>
        <NumDoss type="facture" />
        <div className="data">
          <FactureStatus
            isHidden={isHidden}
            dateFacture={dateFac}
            type="realisation"
          />
          <div className="btns">
            <Button
              className="btn"
              variant="contained"
              style={{
                color: dataDetail ? "black" : "white",
                fontWeight: dataDetail ? "bold" : "",
                backgroundColor: dataDetail
                  ? "rgb(253, 181, 142)"
                  : "rgba(255, 127, 80, 0.73)",
              }}
              onClick={() => this.setState({ dataDetail: true })}>
              Données
            </Button>
            <Button
              className="btn"
              variant="contained"
              style={{
                color: !dataDetail ? "black" : "white",
                fontWeight: !dataDetail ? "bold" : "",
                backgroundColor: !dataDetail
                  ? "rgb(253, 181, 142)"
                  : "rgba(255, 127, 80, 0.73)",
              }}
              disabled={!sortedFac.numclient}
              onClick={() => this.setState({ dataDetail: false })}>
              Détails
            </Button>
          </div>
          {dataDetail ?
            <FactureReaData
              is26Percent={is26Percent}
              sortedFac={sortedFac}
              dossier={dossier}
              mo={mo}
              devisrea={devisrea}
              dateFac={dateFac}
              bdcValue={bdcValue}
              datecomptable={datecomptable}
              handleOpenCreate={this.handleOpenCreate.bind(this)}
              handleChange={this.handleChangeDetailValue.bind(this)}
            />
            : <FactureReaDetail
              puHt={puHt}
              totalht={totalht}
              quantity={quantity}
              amountHt={amountHt}
              tva={tva}
              disableButton={disableButton}
              handleChange={this.handleChange.bind(this)}
              handleChangeTva={this.handleChangeTva.bind(this)}
              checkIndex={this.checkIndex}
              handleSaveClick={this.handleSaveClick.bind(this)}
              handleGenClick={this.handleGenClick.bind(this)}
              handleShowPdf={this.handleShowPdf.bind(this)}
              handleResetQantity={this.loadData}
            />}
        </div>`
        <InformationDialog
          isOpen={openDialog}
          title={'INFORMATION'}
          information={'La date de comptabilité (facturation) n\'est pas communiquée'}
          handleClose={this.handleClose}
        />

        <InformationDialog
          isOpen={openDialogCreateLimite}
          title={'INFORMATION'}
          information={'Vous avez atteint le nombre d\'échéances de la phase réalisation'}
          handleClose={this.handleCloseCreateLimite}
        />

        <YesNoDialog
          isOpen={openDialogCreate}
          title={'CRÉATION FACTURE'}
          question={`Êtes-vous sûr de vouloir créer une nouvelle facture « R${sortedFactures.length + 1} » ?`}
          handleYesClick={this.handleCreateFac}
          handleClose={this.handleCloseCreate}
        />
        <GenPdf file={file} isOpen={pdf} closeDoc={this.closeDoc} scale={true} />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  factures: state.facture.items,
  facturesbydoss: state.facture.facturesbydoss,
  facindex: state.facture.facindex,
  nfacvalue: state.facture.nfacvalue,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  devisrea: state.devis.devisrea,
  contact: state.contact.items,
  echeances: state.echeances.items,
  contratValues: state.contrat.items
});
export default connect(mapStateToProps)(FactureRea);