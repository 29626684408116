import React from 'react';
import history from '../../Routes/history';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fetchFac, fetchAllFac } from "../../../redux/actions/facActions";
class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chevFac: "right",
      chevFacAmendment: "right",
      chevDevis: "right",
      chevContrat: "right",
      chevPgc: "right",
      chevPreDiuo: "right",
      chevVic: "right",
      chevVics: "right",
      chevH: "right",
      chevCalendar: "right",
      chevRj: "right",
      chevEmploymentLaw: "right"
    }
    this.factureWrapper = React.createRef();
    this.factureAmendmentWrapper = React.createRef();
    this.devisWrapper = React.createRef();
    this.contratWrapper = React.createRef();
    this.pgcWrapper = React.createRef();
    this.preDiuoWrapper = React.createRef();
    this.vicWrapper = React.createRef();
    this.vicsWrapper = React.createRef();
    this.heuresWrapper = React.createRef();
    this.calendarWrapper = React.createRef();
    this.rjWrapper = React.createRef();
    this.employmentLawWrapper = React.createRef();
    this.changeFacState = this.changeFacState.bind(this);
    this.changeFacAmendmentState = this.changeFacAmendmentState.bind(this);
    this.changeDevisState = this.changeDevisState.bind(this);
    this.changeContratState = this.changeContratState.bind(this);
    this.changePgcState = this.changePgcState.bind(this);
    this.changePreDiuoState = this.changePreDiuoState.bind(this);
    this.changeVicState = this.changeVicState.bind(this);
    this.changeVicsState = this.changeVicsState.bind(this);
    this.changeHeuresState = this.changeHeuresState.bind(this)
    this.changeCalendarState = this.changeCalendarState.bind(this)
    this.changeRjState = this.changeRjState.bind(this)
    this.changeEmploymentLawState = this.changeEmploymentLawState.bind(this)
  }
  /**
   * Renvoie vers la page conception de la partie facture
   */
  clickToFactureCon = () => {
    history.push("/facture/conception");
    this.props.dispatch(fetchFac('conception'))
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page contrat facture
   */
  clickToContractAmendment = () => {
    history.push("/avenant/contrat");
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page facture avenant
   */
  clickToFacAmendment = () => {
    history.push("/avenant/facture");
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page facture récapitulative avenant
  */
  clickToFacRecapAmendment = () => {
    history.push("/avenant/facture_recap");
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page relance facture avenant
   */
  clickToReminderFacAmendment = () => {
    history.push("/avenant/facture_relance");
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page tableau facture avenant
   */
  clickToTableFacAmendment = () => {
    history.push("/avenant/tableau_facture");
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page tableau facture avenant
   */
  clickToGlobalFacAmendment = () => {
    history.push("/avenant/synthese_facture");
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page réalisation de la partie facture
   */
  clickToFactureReal = () => {
    history.push("/facture/realisation");
    this.props.dispatch(fetchFac('realisation'))
    this.props.changeListState()
  }
  /**
   * Renvoie vers la page facture récapitulative de la partie facture
   */
  clickToFactureRecap = () => {
    history.push("/facture/recapitulative");
    this.props.dispatch(fetchFac('realisation'))
    this.props.changeListState()
  }
  clickToFactureTable = () => {
    history.push("/facture/liste");
    this.props.dispatch(fetchAllFac())
    this.props.changeListState()
  }
  clickToReminder = () => {
    history.push("/facture/relance");
    this.props.changeListState()
  }
  clickToGlobal = () => {
    history.push("/facture/global");
    this.props.changeListState()
  }
  clickToFactureDownload = () => {
    history.push("/facture/download");
  }
  clickToHeuresQuota = () => {
    history.push("/heures/quota");
  }
  clickToHeuresHist = () => {
    history.push("/heures/historique");
  }
  clickToHeuresHtp = () => {
    history.push("/heures/htp");
  }
  clickToCalendarAgenda = () => {
    history.push("/calendrier/agenda");
  }
  clickToCalendarAppointment = () => {
    history.push("/calendrier/rdv");
  }
  clickToCalendarSchedule = () => {
    history.push("/calendrier/planning");
  }
  clickToRjEvaluationRisque = () => {
    history.push("/rj/evaluation");
  }
  clickToRjObservations = () => {
    history.push("/rj/observations");
  }
  clickToRjContacts = () => {
    history.push("/rj/contacts");
  }
  clickToRjSuiviEffectifs = () => {
    history.push("/rj/suivi-effectifs");
  }
  clickToRjValidation = () => {
    history.push("/rj/Validation");
  }
  /**
   * Renvoie vers la page calcul approximatif de la partie devis
   */
  clickToDevisCalcul = () => {
    history.push("/devis/calcul");
  }
  /**
   * Renvoie vers la page conception de la partie devis
   */
  clickToDevisConception = () => {
    history.push("/devis/conception");
  }
  /**
   * Renvoie vers la page réalisation de la partie devis
   */
  clickToDevisRealisation = () => {
    history.push("/devis/realisation");
  }
  /**
   * Renvoie vers la page proposition de la partie devis
   */
  clickToDevisProposition = () => {
    history.push("/devis/proposition");
  }
  /**
   * Renvoie vers la page validation de la partie devis
   */
  clickToDevisValidation = () => {
    history.push("/devis/validation");
  }
  /**
   * Renvoie vers la page de création de dossier de la partie devis
   */
  clickToDossier = () => {
    history.push("/devis/dossier");
  }
  /**
   * Renvoie vers la page données de la partie contrat
   */
  clickToContratDonnees = () => {
    history.push("/contrat/données");
  }
  /**
   * Renvoie vers la page échéances de la partie contrat
   */
  clickToContratEch = () => {
    history.push("/contrat/echeances");
  }
  /**
   * Renvoie vers la page validation de la partie contrat
   */
  clickToContratValid = () => {
    history.push("/contrat/validation");
  }
  /**
 * Renvoie vers la page 1 de la partie pgc
 */
  clickToPgcPage1 = () => {
    history.push("/pgc/page1");
  }
  /**
* Renvoie vers la page 2 de la partie pgc
*/
  clickToPgcPage2 = () => {
    history.push("/pgc/page2");
  }
  /**
* Renvoie vers la page 3 de la partie pgc
*/
  clickToPgcPage3 = () => {
    history.push("/pgc/page3");
  }
  /**
* Renvoie vers la page 4 de la partie pgc
*/
  clickToPgcPage4 = () => {
    history.push("/pgc/page4");
  }
  /**
* Renvoie vers la page 5 de la partie pgc
*/
  clickToPgcPage5 = () => {
    history.push("/pgc/page5");
  }
  /**
* Renvoie vers la page 6 de la partie pgc
*/
  clickToPgcPage6 = () => {
    history.push("/pgc/page6");
  }

  /**
* Renvoie vers la page 7 de la partie pgc
*/
  clickToPgcPage7 = () => {
    history.push("/pgc/validation");
  }
  /**
 * Renvoie vers la page 1 de la partie pre diuo
 */
  clickToPreDiuoPage1 = () => {
    history.push("/prediuo/page1");
  }
  /**
 * Renvoie vers la page 2 de la partie pre diuo
 */
  clickToPreDiuoPage2 = () => {
    history.push("/prediuo/page2");
  }
  /**
 * Renvoie vers la page 3 de la partie pre diuo
 */
  clickToPreDiuoPage3 = () => {
    history.push("/prediuo/page3");
  }
  /**
 * Renvoie vers la page validation de la partie pre diuo
 */
  clickToPreDiuoPage4 = () => {
    history.push("/prediuo/validation");
  }

  /**
 * Renvoie vers la page 1 de la partie vic
 */
  clickToVicPage1 = () => {
    history.push("/vic/page1");
  }
  /**
* Renvoie vers la page 2 de la partie vic
*/
  clickToVicPage2 = () => {
    history.push("/vic/page2");
  }
  /**
* Renvoie vers la page 3 de la partie vic
*/
  clickToVicPage3 = () => {
    history.push("/vic/page3");
  }
  /**
* Renvoie vers la page 4 de la partie vic
*/
  clickToVicPage4 = () => {
    history.push("/vic/page4");
  }
  /**
* Renvoie vers la page 5 de la partie vic
*/
  clickToVicPage5 = () => {
    history.push("/vic/page5");
  }
  /**
* Renvoie vers la page 6 de la partie vic
*/
  clickToVicPage6 = () => {
    history.push("/vic/page6");
  }

  /**
* Renvoie vers la page 7 de la partie vic
*/
  clickToVicPage7 = () => {
    history.push("/vic/page7");
  }

  /**
   * Renvoie vers la page 8 de la partie vic
   */
  clickToVicPage8 = () => {
    history.push("/vic/page8");
  }

  clickToVicValid = () => {
    history.push("/vic/validation");
  }

  clickToVicsPage1 = () => {
    history.push("/vic/page-1");
  }
  /**
* Renvoie vers la page 2 de la partie vic
*/
  clickToVicsPage2 = () => {
    history.push("/vic/page-2");
  }
  /**
* Renvoie vers la page 3 de la partie vic
*/
  clickToVicsPage3 = () => {
    history.push("/vic/page-3");
  }
  /**
* Renvoie vers la page 4 de la partie vic
*/
  clickToVicsPage4 = () => {
    history.push("/vic/page-4");
  }
  /**
* Renvoie vers la page 5 de la partie vic
*/
  clickToVicsPage5 = () => {
    history.push("/vic/page-5");
  }
  /**
* Renvoie vers la page 6 de la partie vic
*/
  clickToVicsPage6 = () => {
    history.push("/vic/page-6");
  }

  /**
* Renvoie vers la page 7 de la partie vic
*/
  clickToVicsPage7 = () => {
    history.push("/vic/page-7");
  }

  /**
   * Renvoie vers la page 8 de la partie vic
   */
  clickToVicsPage8 = () => {
    history.push("/vic/page-8");
  }

  clickToVicsValid = () => {
    history.push("/vic/validations");
  }

  clickToVicEmploymentLaw = () => {
    history.push("/vic/code-travail");
  }

  clickToRjEmploymentLaw = () => {
    history.push("/rj/code-travail");
  }

  changeFacState() {
    const factureWrapper = this.factureWrapper.current;
    factureWrapper.classList.toggle("is-fac-enabled");
    if (this.state.chevFac === "right") this.setState({ chevFac: "down" })
    else this.setState({ chevFac: "right" })
  }

  changeFacAmendmentState() {
    const factureAmendmentWrapper = this.factureAmendmentWrapper.current;
    factureAmendmentWrapper.classList.toggle("is-fac-amendment-enabled");
    if (this.state.chevFacAmendment === "right") this.setState({ chevFacAmendment: "down" })
    else this.setState({ chevFacAmendment: "right" })
  }

  changeDevisState() {
    const devisWrapper = this.devisWrapper.current;
    devisWrapper.classList.toggle("is-devis-enabled");
    if (this.state.chevDevis === "right") this.setState({ chevDevis: "down" })
    else this.setState({ chevDevis: "right" })
  }

  changeContratState() {
    const contratWrapper = this.contratWrapper.current;
    contratWrapper.classList.toggle("is-contrat-enabled");
    if (this.state.chevContrat === "right") this.setState({ chevContrat: "down" })
    else this.setState({ chevContrat: "right" })
  }

  changePgcState() {
    const pgcWrapper = this.pgcWrapper.current;
    pgcWrapper.classList.toggle("is-pgc-enabled");
    if (this.state.chevPgc === "right") this.setState({ chevPgc: "down" })
    else this.setState({ chevPgc: "right" })
  }

  changePreDiuoState() {
    const preDiuoWrapper = this.preDiuoWrapper.current;
    preDiuoWrapper.classList.toggle("is-prediuo-enabled");
    if (this.state.chevPreDiuo === "right") this.setState({ chevPreDiuo: "down" })
    else this.setState({ chevPreDiuo: "right" })
  }

  changeVicState() {
    const vicWrapper = this.vicWrapper.current;
    vicWrapper.classList.toggle("is-vic-enabled");
    if (this.state.chevVic === "right") this.setState({ chevVic: "down" })
    else this.setState({ chevVic: "right" })
  }

  changeVicsState() {
    const vicsWrapper = this.vicsWrapper.current;
    vicsWrapper.classList.toggle("is-vic-enabled");
    if (this.state.chevVics === "right") this.setState({ chevVics: "down" })
    else this.setState({ chevVics: "right" })
  }

  changeHeuresState() {
    const heuresWrapper = this.heuresWrapper.current;
    heuresWrapper.classList.toggle("is-heures-enabled");
    if (this.state.chevH === "right") this.setState({ chevH: "down" })
    else this.setState({ chevH: "right" })
  }

  changeCalendarState() {
    const calendarWrapper = this.calendarWrapper.current;
    calendarWrapper.classList.toggle("is-calendar-enabled");
    if (this.state.chevCalendar === "right") this.setState({ chevCalendar: "down" })
    else this.setState({ chevCalendar: "right" })
  }

  changeRjState() {
    const rjWrapper = this.rjWrapper.current;
    rjWrapper.classList.toggle("is-rj-enabled");
    if (this.state.chevRj === "right") this.setState({ chevRj: "down" })
    else this.setState({ chevRj: "right" })
  }

  changeEmploymentLawState() {
    const employmentLawWrapper = this.employmentLawWrapper.current;
    employmentLawWrapper.classList.toggle("is-employmentlaw-enabled");
    if (this.state.chevEmploymentLaw === "right") this.setState({ chevEmploymentLaw: "down" })
    else this.setState({ chevEmploymentLaw: "right" })
  }

  render() {
    return (
      <div ref={this.props.listWrapper} className="listWrapper">
        <div className="list">
          <p onClick={this.changeDevisState}>Devis
            <span>{this.state.chevDevis === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
          </p>
          <div ref={this.devisWrapper} className="listDevis">
            <p onClick={this.clickToDossier}>Création d'un nouveau dossier</p>
            <p onClick={this.clickToDevisCalcul}>Calcul approximative du devis </p>
            <p onClick={this.clickToDevisConception}>Conception </p>
            <p onClick={this.clickToDevisRealisation}>Réalisation</p>
            <p onClick={this.clickToDevisProposition}>Devis proposé</p>
            <p onClick={this.clickToDevisValidation}>Devis validé</p>
          </div>

          <p onClick={this.changeContratState}>Contrat
            <span>{this.state.chevContrat === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
          </p>
          <div ref={this.contratWrapper} className="listContrat">
            <p onClick={this.clickToContratDonnees}>Données Contrat</p>
            <p>Validation Contrat </p>{/* onClick={this.clickToContratValid} */}
            <p onClick={this.clickToContratEch}> Avenant Echéances</p>
            <p>Validation Avenant</p>
            <p>Envoi du PGC</p>
          </div>
          <p onClick={this.changePgcState}>PGC
            <span>{this.state.chevPgc === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
          </p>
          <div ref={this.pgcWrapper} className="listPGC">
            <p onClick={this.clickToPgcPage1}>Page de garde</p>
            <p onClick={this.clickToPgcPage2}>Présentation du projet</p>
            <p onClick={this.clickToPgcPage3}>Mesures d'organisation générale</p>
            <p onClick={this.clickToPgcPage4}>Mesures de coordination </p>
            <p onClick={this.clickToPgcPage5}>Interférences avec les occupants</p>
            <p onClick={this.clickToPgcPage6}>Information nécessaire au CSPS</p>
            <p onClick={this.clickToPgcPage7}>Validation</p>
          </div>
          <p onClick={this.changePreDiuoState}>Pré DIUO
            <span>{this.state.chevPreDiuo === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
          </p>
          <div ref={this.preDiuoWrapper} className="listPreDiuo">
            <p onClick={this.clickToPreDiuoPage1}>Page de garde</p>
            <p onClick={this.clickToPreDiuoPage2}>Information nécessaire au CSPS</p>
            <p onClick={this.clickToPreDiuoPage3}>Les éléments de construction</p>
            <p onClick={this.clickToPreDiuoPage4}>Validation</p>
          </div>
          <p onClick={this.changeVicState}>VIC
            <span>{this.state.chevVic === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
          </p>
          <div ref={this.vicWrapper} className="listVIC">
            <p onClick={this.clickToVicPage1}>Page 1</p>
            <p onClick={this.clickToVicPage2}>Page 2</p>
            <p onClick={this.clickToVicPage3}>Page 3</p>
            <p onClick={this.clickToVicPage4}>Page 4</p>
            <p onClick={this.clickToVicPage5}>Page 5</p>
            <p onClick={this.clickToVicPage6}>Page 6</p>
            <p onClick={this.clickToVicPage7}>Page 7</p>
            <p onClick={this.clickToVicPage8}>Page 8</p>
            <p onClick={this.clickToVicValid}>Validation</p>
          </div>
          <p onClick={this.changeVicsState}>VIC nouveaux lots
            <span>{this.state.chevVics === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
          </p>
          <div ref={this.vicsWrapper} className="listVIC">
            <p onClick={this.clickToVicsPage1}>Page 1</p>
            <p onClick={this.clickToVicsPage2}>Page 2</p>
            <p onClick={this.clickToVicsPage3}>Page 3</p>
            <p onClick={this.clickToVicsPage4}>Page 4</p>
            <p onClick={this.clickToVicsPage5}>Page 5</p>
            <p onClick={this.clickToVicsPage6}>Page 6</p>
            <p onClick={this.clickToVicsPage7}>Page 7</p>
            <p onClick={this.clickToVicsPage8}>Page 8</p>
            <p onClick={this.clickToVicsValid}>Validation</p>
          </div>



          <p>Chiffre <span><FontAwesomeIcon icon={faChevronRight} /></span></p>
          <p>Contacts <span><FontAwesomeIcon icon={faChevronRight} /></span></p>

          <p onClick={this.changeFacState}>Factures
            <span>
              {this.state.chevFac === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </p>
          <div ref={this.factureWrapper} className="listFac">
            <p onClick={this.clickToFactureCon}>Facture Conception </p>
            <p onClick={this.clickToFactureReal}>Facture Réalisation </p>
            <p onClick={this.clickToFactureRecap}>Facture Récapitulative </p>
            <p onClick={this.clickToReminder}>Relance de Facturation </p>
            <p onClick={this.clickToFactureTable}>Tableau de Facturation </p>
            <p onClick={this.clickToGlobal}>Tableau Synthèse de Facturation </p>
          </div>

          <p onClick={this.changeFacAmendmentState}>Avenant Facturation
            <span>
              {this.state.chevFacAmendment === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </p>
          <div ref={this.factureAmendmentWrapper} className="listFacAmendment">
            <p onClick={this.clickToContractAmendment}>Contrat Avenant </p>
            <p onClick={this.clickToFacAmendment}>Facture Avenant </p>
            <p onClick={this.clickToFacRecapAmendment}>Facture Avenant Récapitulative </p>
            <p onClick={this.clickToReminderFacAmendment}>Facture Avenant Relance</p>
            <p onClick={this.clickToTableFacAmendment}>Factures Avenant Tableau</p>
            {/* <p >Tableau Synthèse Avenant </p> */}
            <p onClick={this.clickToGlobalFacAmendment}>Tableau Synthèse Avenant</p>
          </div>

          <p onClick={this.changeHeuresState}>Heures
            <span>
              {this.state.chevH === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </p>
          <div ref={this.heuresWrapper} className="listHeures">
            <p onClick={this.clickToHeuresQuota}>Quota d'heures</p>
            <p onClick={this.clickToHeuresHist}>Historique </p>
            <p onClick={this.clickToHeuresHtp}>Heures Totales Prévues</p>
          </div>

          <p onClick={this.changeCalendarState}>Calendrier
            <span>
              {this.state.chevCalendar === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </p>
          <div ref={this.calendarWrapper} className="listCalendar">
            <p onClick={this.clickToCalendarAgenda}>Agenda</p>
            <p onClick={this.clickToCalendarAppointment}>Intervenants</p>
            <p onClick={this.clickToCalendarSchedule}>Relevé de rendez-vous</p>
          </div>

          <p onClick={this.changeRjState}>RJ
            <span>
              {this.state.chevRj === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </p>
          <div ref={this.rjWrapper} className="listRj">
            <p onClick={this.clickToRjEvaluationRisque}>Evaluation Des Risques</p>
            <p onClick={this.clickToRjObservations}>Observations</p>
            <p onClick={this.clickToRjContacts}>Contacts</p>
            <p onClick={this.clickToRjSuiviEffectifs}>Suivi Des Effectifs</p>
            <p onClick={this.clickToRjValidation}>Validation</p>
          </div>

          <p onClick={this.changeEmploymentLawState}>Lots & Code du travail
            <span>
              {this.state.chevEmploymentLaw === "right" ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </p>
          <div ref={this.employmentLawWrapper} className="listEmploymentLaw">
            <p onClick={this.clickToVicEmploymentLaw}>VIC</p>
            <p onClick={this.clickToRjEmploymentLaw}>RJ</p>
          </div>
        </div>
      </div>
    )
  }
}
export default connect()(List);