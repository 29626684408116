import React from 'react';
import { IconButton } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import EmailIcon from '@material-ui/icons/Email';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from "moment";

moment.locale('fr');

const AppointmentList = ({ appointments, setIsAdd, setDeleteId, setNewAppointment, setOpenDelDialog, handleSortAppointments, setIntervenant, setIsConfirmationAppointment, setSendEmail }) => {

  return (
    <>{
      appointments.length ?
        <table className="appointment-list">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={13}><strong>Intervenants</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label">Type</td>
              <td className="custom-cell-label">Entreprise</td>
              <td className="custom-cell-label">Lot</td>
              <td className="custom-cell-label">Contact</td>
              <td className="custom-cell-label">Téléphone</td>
              <td className="custom-cell-label">Mail</td>
              <td className="custom-cell-label" onClick={() => handleSortAppointments('intervention')} style={{ cursor: 'pointer' }}>Date d'intervention</td>
              <td className="custom-cell-label" onClick={() => handleSortAppointments('appointment')} style={{ cursor: 'pointer' }}>Date du RDV</td>
              <td style={{ width: '15px', borderBlockEnd: '1px solid #C2C1C1' }}></td>
              <td className="custom-cell-label">PPSPS</td>
              <td style={{ width: '15px', borderBlockEnd: '1px solid #C2C1C1' }}></td>
              <td style={{ width: '15px', borderBlockEnd: '1px solid #C2C1C1' }}></td>
              <td style={{ width: '15px', borderBlockEnd: '1px solid #C2C1C1' }}></td>
            </tr>
            {appointments.map((item, i) => (
              <tr className="custom-cell" key={`appointment-${i}`} style={{ backgroundColor: moment.utc(new Date()).format('DD/MM/YYYY') == moment.utc(new Date(item.appointment_start)).format('DD/MM/YYYY') ? '#d5ffd5' : '' }}>
                <td className="custom-cell-label" style={{ textAlign: 'center' }}>{item.type}</td>
                <td className="custom-cell-label" style={{ textAlign: 'center', color: item.company ? '' : 'red' }}>{item.company === '' ? 'À définir' : item.company}</td>
                <td className="custom-cell-label" style={{ textAlign: 'center' }}>{item.lot}</td>
                <td className="custom-cell-label" style={{ textAlign: 'center', color: item.representative ? '' : 'red' }}>{item.representative === '' ? 'À définir' : item.representative}</td>
                <td className="custom-cell-label" style={{ textAlign: 'center', color: item.tel ? '' : 'red' }}>{item.tel === '' ? 'À définir' : item.tel}</td>
                <td className="custom-cell-label" style={{ textAlign: 'center', color: item.mail ? '' : 'red', width: '235px' }}>{item.mail === '' ? 'À définir' : item.mail}</td>
                <td className="custom-cell-label" style={{ textAlign: 'center', color: item.intervention_date ? '' : 'red' }}>{item.intervention_date ? moment(item.intervention_date).format('DD/MM/YYYY') : 'À définir'}</td>
                <td className="custom-cell-label" style={{ textAlign: 'center', color: item.appointment_start ? '' : 'red' }} >
                  {item.appointment_start ?
                    `${moment.utc(item.appointment_start).format('DD/MM/YYYY')} - ${moment.utc(item.appointment_start).format('HH:mm')} à ${moment.utc(item.appointment_end).format('HH:mm')}`
                    : 'À confirmer'}
                </td>
                <td className="custom-cell-label">
                  <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="send"
                    size="small"
                    className="send"
                    onClick={() => {
                      setIntervenant(item)
                      setIsConfirmationAppointment(true)
                      setSendEmail(true)
                    }}
                    disabled={!item.appointment_start || !item.appointment_end || !item.mail}
                    title="Envoyer confirmation RDV"
                  >
                    <EventAvailableIcon style={{ color: item.confirmation_sent ? 'green' : "", fontSize: '28px' }} />
                  </IconButton>
                </td>
                {item.type.trim().toLowerCase().includes('vic') ? <td className="custom-cell-label" style={{ textAlign: 'center', color: item.ppsps ? 'green' : 'red' }}><input type="checkbox" checked={true} readOnly style={{ width: '20px', height: '20px', accentColor: item.ppsps ? 'green' : 'red' }} /></td> : <td></td>}
                <td className="custom-cell-label">
                  <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="edit"
                    size="small"
                    className="edit"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setIsAdd(false)
                      setNewAppointment(item)
                    }}
                    title="Modifier intervenant"
                  >
                    <Edit />
                  </IconButton>
                </td>
                <td className="custom-cell-label">
                  <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="delete"
                    size="small"
                    className="delete"
                    onClick={() => {
                      setDeleteId(item.id)
                      setOpenDelDialog(true)
                    }}
                    title="Supprimer intervenant"
                  >
                    <Delete />
                  </IconButton>
                </td>
                <td className="custom-cell-label">
                  <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="send"
                    size="small"
                    className="send"
                    onClick={() => {
                      setIntervenant(item)
                      setIsConfirmationAppointment(false)
                      setSendEmail(true)
                    }}
                    disabled={!item.mail || !item.intervention_date}
                    title="Envoyer un mail"
                  >
                    <ContactMailIcon style={{ color: item.email_sent ? 'green' : "" }} />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table> : null
    }
    </>
  );
};

export default AppointmentList;