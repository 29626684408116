import { combineReducers } from 'redux'
import facture from './facReducer'
import user from './userReducer'
import notif from './notifReducer'
import calcDevis from './calcDevisReducer'
import devis from './devisReducer'
import contact from './contactReducer'
import dossier from './dossierReducer'
import contrat from './contratReducer'
import echeances from './echeancesReducer'
import email from './emailReducer'
import vic from './vicReducer'
import entreprise from './entrepriseReducer'
import rj from './rjReducer'
import quota from './quotaReducer'
import reminder from './reminderReducer'
import reminderFacAmendment from './reminderFacAmendmentReducer'
import facAmendment from './facAmendmentReducer'
import pgc from './pgcReducer'
import preDiuo from './preDiuoReducer'

const rootReducer = combineReducers({
  facture, user, notif, calcDevis, devis, contact, dossier, contrat, email, echeances, vic, entreprise, rj, quota, reminder, facAmendment, reminderFacAmendment, pgc, preDiuo
})

export default rootReducer