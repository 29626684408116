// Structure complète des données
export const ELEMENT_CONFIGS = {
  "Toiture terrasses et skydôme": {
    quantities: {
      headers: ["Nombre", "Surface (m²)", "Unité (kJ)"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Le nombre et nature des accès prévu en toiture terrasse",
        "Les cheminements prévus sur toiture terrasse",
        "Les moyens d'arimage pour intervention de courte durée",
        "Les possibilités de mise en place de garde-corps ou filets de protection",
        "Résistance des skydômes installés",
        "Présence d'échelle à crinoline"
      ]
    }
  },

  "Façades": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les moyens prévus pour le ravalement de la façade (préciser la rue)",
        "Les moyens prévus pour le ravalement des façades sans regard sur la voie publique"
      ]
    }
  },

  "Locaux techniques": {
    type: "text",
    content: `***· Niveau  R + 1 + 2 +3 … :***
Cuve
Local bâches thermique
Stockage E.P
***· Niveau R-1:***
Le local technique accessible en partant d'où ?
Local CFO
Bassin de rétention préciser ?
***· RDC :***
Arrêté du 19 juin 2015 modifiant l'arrêté du 31 janvier 1986 modifié relatif à la protection contre l'incendie des bâtiments d'habitation
Préciser si grilles de désenfumage ? (famille de type 3).
Préciser les lieux et espaces P.M.R & de secours`
  },

  "Jardinières": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "L'ensemble des jardinières (végétaux)",
        "L'accès et le cheminement pour l'entretien prévenant des risques de chute de hauteur."
      ]
    }
  },

  "Sas d'entrée": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les moyens prévus pour le nettoyage des vitres et la maintenance du mécanisme",
        "L'accès et le cheminement pour l'entretien prévenant des risques de chute de hauteur"
      ]
    }
  },
  "Réseaux VMC": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Prévoir les dispositifs ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "La coupure électrique de proximité pour tous les appareils afin de couper l'alimentation, avant toute intervention",
        "Des prises de courant 20 A+ Terre, afin d'éviter des rallonges de plus de 25 mètres",
        "Un espace de travail au droit des équipements à entretenir (gabarit minimum 1 m² au sol)",
        "Un volume suffisant autour de l'appareil et du réseau des gaines, en particulier au droit des tés",
        "Des accès pour le ramonage au droit des tés des conduits VMC"
      ]
    }
  },

  "Ascenseurs": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les conditions d'utilisation des ascenseurs y compris durant les travaux ainsi que tous autre moyens de levage de matériel et ou matériaux et de personnes",
        "Les notes et conventions d'utilisations communes",
        "Le carnet de maintenance",
        "Les modes d'accès",
        "Les consignations, déconsignations",
        "Les coordonnées du mainteneur",
        "Un justificatif montrant que la cabine est bien anti feu",
        "Le dispositif de communication téléphonique ou inter phonique dans la cabine – REM Voie  etc.",
        "La conformité quant à l'arrêté du 18/11/2004  relatif aux travaux de sécurité dans les installations, d'ascenseurs en formant l'ascensoriste  en vue de lui délivrer une habilitation électrique, de le former  aux modes opératoires, aux interventions  et en particulier aux procédures de consignation des appareils",
        "La manutention des charges lourdes. Durant les phases de montage, chantiers et  ou de transformation, l'ascensoriste doit manœuvrer des charges lourdes (porte palière 100 kg, moteur treuil > 300 kg) pouvant provoquer des problèmes lombaires. Les risques liés à la Co-activité avec d'autres métiers (second œuvre du BTP) doivent être pris en compte",
        "Les modes opératoires car les équipements de travail deviennent dangereux s'ils ne sont pas définis ou ne sont pas respectés",
        "Les moyens de sécurité adapté au type d'ascenseur dont un système d'arrêt de chute via point d'accrochage sur cabine",
        "Proscrire les travailleurs isolés et l'annoncer clairement dans tous les documents y compris les cahiers des charges",
        "Le nettoyage après chaque opération pour combattre le risque chimique avec les huiles, graisse et solvants organiques pour la lubrification d'organes en mouvement pouvant entraîner des intoxications / irritations"
      ]
    }
  },

  "Éclairage et ouvertures": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les moyens disponibles et ceux que les entreprises doivent prévoir pour la réalisation de leurs travaux afin d'assurer l'éclairage du chantier",
        "Les moyens d'accès de toutes les énergies et fluides ainsi que les conditions d'utilisation durant les travaux",
        "Les notes et conventions d'utilisations communes",
        "Le carnet de maintenance",
        "Le contrat de maintenance",
        "Les modes d'accès",
        "Les consignations, déconsignations",
        "L'ensemble des équipements qui devront être accessibles de plain-pied en particulier dans les escaliers, caves etc.",
        "Les opérations de maintenance et entretien ne nécessitant pas le recours au travail en hauteur avec les équipements à bascule ou levant…"
      ]
    }
  },

  "Blocs autonomes d'éclairage de sécurité": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "L'ensemble des installations de blocs autonomes d'éclairage et de sécurité qui devront être accessibles de plain-pied en particulier dans les escaliers, caves etc."
      ]
    }
  },

  "Ouvertures": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les hauteurs, formats, moyens d'accès et d'entretien des ouvertures fenêtres"
      ]
    }
  },

  "Chaufferie et chauffage": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les opérations afférentes prévues",
        "Les moyens d'accès et les conditions d'utilisation y compris approvisionnement durant les travaux",
        "Les notes et conventions d'utilisations communes",
        "Le carnet de maintenance",
        "Le contrat de maintenance",
        "Les modes d'accès",
        "Les consignations, déconsignations"
      ]
    }
  },
  "VRD": {
    quantities: {
      headers: ["Nombre", "Unité (ml)"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les disposition de desserte, voirie, réseaux, raccordements … Eau, électricité, gaz… en spécifiant Toutes les existences de risques et contraintes environnementales & spécifiques liés à la présence de lignes électriques aériennes, de canalisations de gaz, de cours d'eau…"
      ]
    }
  },

  "Aménagements intérieurs": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "L'organisation pour identifier les zones à circulations croisées",
        "Les modes de livraisons: créneaux horaires, parking d'attente prévu pour ne pas engorger la voie publique et les résidents",
        "L'identification des lieux de déchargement et chargement, les zones de stockages et stationnement pour les véhicules, engins, matériels et produits",
        "L'organisation des stockages et rangements (engins, véhicules, palettes, produits …)",
        "L'organisation du balisage avec séparation matérielle des flux de circulation pour les piétons afin d'éviter les risques liés au passage d'engins \nLa séparation des flux chantier-résidents autres opérations de maintenance et d'urgence"
      ]
    }
  },

  "Clôtures": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "L'organisation du balisage avec séparation matérielle des flux de circulation pour les piétons afin d'éviter les risques liés au passage d'engins",
        "Les moyens et le type de clôture du chantier, incluant les modes d'ouverture et fermeture",
        "Les accès Secours pompiers, SAMU et issues de secours signalés et réservés, la présence de signalisation et éclairage de sécurité"
      ]
    }
  },

  "Espaces verts": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les arrêtés municipaux, préfectoraux, ministériels, présidentiels pouvant impacter le chantier ont-ils été pris en compte ainsi que les particularités environnementales et de voisinage"
      ]
    }
  },

  "Convention d'utilisation": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les listes et conventions d'utilisation communes et de moyens logistiques communs de matériels, outillages, passages (échafaudages, ascenseurs, passages, lift, etc.)",
        "Les modalités et procédures des visiteurs : accueil, accompagnement, parcours …, attention au strict respect de la règlementation applicable"
      ]
    }
  },

  "Consignations – Désignations": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les procédures de consignations et déconsignations des installations fluides, gaz, toutes énergies…",
        "Méthodologie, mode opératoire et consignes particulières à fournir avant l'opération et à joindre au PPSPS"
      ]
    }
  },

  "Déchets (conteneurs ordure)": {
    quantities: {
      headers: ["Nombre de conteneurs", "Surface (en m²)", "Unité (ml)"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Le mode d'organisation et d'évacuation des déchets - stockages-conteneurs, accès des collectes tri sélectifs, transports, BSD, traçabilité. Respect de toute charte chantier propre"
      ]
    }
  },

  "Bruits et vibrations": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "L'information et la signalisation des lieux et outillages bruyants pour les opérateurs et les résidents et former les opérateurs concernés au préalable."
      ]
    }
  },
  "Serrurerie": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les installations de fermetures provisoires des portes chantiers, accès, consignations déconsignations et la gestion des clefs",
        "Un passe partout pour les interventions d'urgence ou cas de force majeure permettant l'ouverture des serrureries par clefs matérielle ou électronique ou informatique"
      ]
    }
  },

  "Incendie": {
    quantities: {
      headers: ["Nombre d'objets", "Surface (en m²)", "Unité (ml)"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les installations poteaux, bornes, vannes, extincteurs, lanterneaux, grilles de désenfumage, colonne sèche et fournir plans des installations durant les travaux ainsi que des aménagements",
        "Les protections des installations à mettre en place (distances de sécurité, moyens matérialisés et leurs signalisations) et marquages des installations (panneaux, affiches, plaques marquages au sol)",
        "Les accès pour les vérifications et la maintenance, les zones de commandes et localisation d'avertisseur de batterie",
        "Les moyens d'accès et les conditions d'utilisation y compris approvisionnement durant les travaux",
        "Les listes des moyens d'extinction",
        "Les notes et conventions d'utilisations communes",
        "Le carnet de maintenance",
        "Le contrat de maintenance",
        "Les modes d'accès",
        "Les consignations, déconsignations"
      ]
    }
  },

  "Ventilation haute et basse": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "L'accès aux conduits, gaines, filtres, trappes, y compris dans les faux plafonds",
        "Si les filtres seront bien aux lieux de forte concentration potentielle ; s'ils possèdent des évents de décharge pour empêcher l'éjection d'éléments dangereux",
        "Si les ventilateurs ne seront pas à proximité d'un moteur électrique ou variateur ou appareil avec pâles",
        "Si Les bacs de réception des poussières seront d'une contenance limitée pour  être évacués sans épandage et facilement accessibles",
        "La vitesse de transport dans les gaines pour éviter l'accumulation des polluants",
        "Le contrôle et le nettoyage du réseau (prévoir des trappes de visite)",
        "Si les éléments constitutifs de l'installation et les gaines seront bien reliés à la terre",
        "Si les gaines seront fabriquées à l'aide d'un matériau conducteur afin d'éviter l'accumulation de charges électrostatiques",
        "Les matières premières envisagées pour analyser leur niveau de risque, rappel : le polluant principal",
        "Indiquer la méthodologie d'étude du système de ventilation, sa conception qui doit comprendre la particularité des piscines et notamment celles traitées contre des expositions importantes de leurs salariés au risque chimique encouru par les travailleurs de ces secteurs et, d'autre part, proposer des solutions techniques visant la réduction des nuisances et la maîtrise de la qualité des atmosphères de travail",
        "Une ventilation mécanique dans les locaux en plus de la ventilation naturelle ou un captage à la source des gaz d'échappement pour éviter l'accumulation de gaz et la création d'un espace confiné",
        "Les solutions techniques de prévention développées et adaptées à la nature du domaine d'activité pour  évaluer leur efficacité"
      ]
    }
  },

  "Portes et fermetures automatiques": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les moyens d'accès et les conditions d'utilisation y compris approvisionnement durant les travaux",
        "Les notes et conventions d'utilisations communes",
        "Le carnet de maintenance",
        "Le contrat de maintenance",
        "Les modes d'accès",
        "Les consignations, déconsignations"
      ]
    }
  },

  "Plomberie et tuyauterie": {
    quantities: {
      headers: ["Nombre", "Unité (ml)"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Le composant des conduits, canalisations, évacuations, raccords réseau plomberie, siphons et la liste des produits, outils et autres pouvant être utilisés pour débouchage, curetage, etc.",
        "Les moyens d'accès et les conditions d'utilisation y compris approvisionnement durant les travaux",
        "L'accès, aux conduits, canalisations, évacuations, raccords réseau plomberie, siphons, de tous sanitaires, lavabos, douches, éviers…"
      ]
    }
  },

  "Espaces d'attente sécurisés": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les modes d'accès",
        "Les justificatifs montrant que les espaces offrent une protection aux fumées, aux flammes, au rayonnement thermique et à la ruine du bâtiment pour une durée > 1 heure"
      ]
    }
  },

  "Dispositions pour P.M.R": {
    quantities: {
      headers: ["Nombre", "Surface (en m²)", "Unité (ml)"],
      rows: 1
    },
    informations: {
      headers: ["Préciser les informations ci dessous", "Réponse du MOE ou MO"],
      rows: [
        "Les dispositions prises pour assurer aux personnes handicapées l'usage des services"
      ]
    }
  },

  "Permis feu": {
    quantities: {
      headers: ["Nombre", "Unité"],
      rows: 1
    },
    informations: {
      headers: ["Fournir les informations ci dessous:", "Réponse du MOE ou MO"],
      rows: [
        "Le permis feu rempli et tamponné par l'entreprise concernée"
      ]
    }
  }
};


export const CONSTRUCTION_ELEMENTS = [
  "Toiture terrasses et skydôme",
  "Façades",
  "Locaux techniques",
  "Jardinières",
  "Sas d'entrée",
  "Réseaux VMC",
  "Ascenseurs",
  "Éclairage et ouvertures",
  "Blocs autonomes d'éclairage de sécurité",
  "Ouvertures",
  "Chaufferie et chauffage",
  "VRD",
  "Aménagements intérieurs",
  "Clôtures",
  "Espaces verts",
  "Convention d'utilisation",
  "Consignations – Désignations",
  "Déchets (conteneurs ordure)",
  "Bruits et vibrations",
  "Serrurerie",
  "Incendie",
  "Ventilation haute et basse",
  "Portes et fermetures automatiques",
  "Plomberie et tuyauterie",
  "Espaces d'attente sécurisés",
  "Dispositions pour P.M.R",
  "Permis feu"
];