import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from '@material-ui/core';
import LoadingOverlay from "../../LoadingOverlay";
import moment from "moment";
import { format } from 'date-fns';
import 'moment/locale/fr';
import Header from "../../Header/Header";
import YesNoDialog from '../../YesNoDialog';
import { getCalendarData, addEvent, updateEvent, deleteEvent } from "../Utils";
import authHeader from '../../Utils';
import history from '../../Routes/history';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../Styles/Calendar/Agenda.scss";

moment.locale('fr');
const localizer = momentLocalizer(moment);
const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const messages = {
  allDay: "Toute la journée",
  previous: "Précédent",
  next: "Suivant",
  today: "Aujourd'hui",
  month: "Mois",
  week: "Semaine",
  day: "Jour",
  agenda: "Agenda",
  date: "Date",
  time: "Heure",
  event: "Événement",
  noEventsInRange: "Aucun événement dans cette plage",
  showMore: (total) => `+${total} de plus`
};

const Agenda = () => {
  const textFieldStyle = isChrome ? { width: '75px', marginLeft: '15px' } : { width: '50px', marginLeft: '15px' };
  const dossiers = useSelector((state) => state.dossier.items);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [userId, setUserId] = useState(null);
  const [eventsGoogleAgenda, setEventsGoogleAgenda] = useState([]);
  const [eventsErpAgenda, setEventsErpAgenda] = useState([]);
  const [lotList, setLotList] = useState([]);

  const [newEvent, setNewEvent] = useState({
    id_user: userId,
    ndossier: null,
    lot: null,
    title: "",
    start: "",
    end: "",
    color: "#4caf50",
    description: ""
  });

  const [openDelDialog, setOpenDelDialog] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleGetAllLots = async () => {
    setIsLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const res = await fetch("/api/rj/getAllLots", requestOptions);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      setLotList(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des lots:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchCalendar = async (date = currentDate) => {
    try {
      setIsLoading(true);
      const startOfMonth = moment(date).startOf('month').toDate();
      const endOfMonth = moment(date).endOf('month').toDate();

      const { events } = await getCalendarData(userId, startOfMonth, endOfMonth);

      const allEvents = events
        .filter(event => event.start && event.end)
        .map(event => ({
          ...event,
          start: new Date(event.id ? moment.utc(event.start).format("YYYY-MM-DD HH:mm") : event.start),
          end: new Date(event.id ? moment.utc(event.end).format("YYYY-MM-DD HH:mm") : event.end)
        }));

      setEventsGoogleAgenda(allEvents.filter(event => !event.id_user));
      setEventsErpAgenda(allEvents.filter(event => event.id_user));

    } catch (error) {
      console.error('Error fetching calendar:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const user = authHeader()
    if (user['x-access-token'] === undefined) {
      history.push('/login')
      return
    }
    setUserId(user.id);
    handleGetAllLots();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchCalendar(currentDate);
    }
  }, [userId, currentDate]);

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
  };

  const fetchCalendarRef = useRef(fetchCalendar);

  const handleDateChange = (event) => {
    const date = event.target.value;
    setNewEvent(prev => ({
      ...prev,
      start: moment(date + 'T' + moment(prev.start).format('HH:mm')).toDate(),
      end: moment(date + 'T' + moment(prev.end).format('HH:mm')).toDate()
    }));
  };

  const handleStartTimeChange = (event) => {
    const time = event.target.value;
    setNewEvent(prev => {
      const newStart = moment(prev.start);
      const [hours, minutes] = time.split(':').map(Number);
      const minutesFromMidnight = hours * 60 + minutes;

      newStart.startOf('day').add(minutesFromMidnight, 'minutes');

      return {
        ...prev,
        start: newStart.toDate()
      };
    });
  };

  const handleEndTimeChange = (event) => {
    const time = event.target.value;
    setNewEvent(prev => {
      const newEnd = moment(prev.end);
      const [hours, minutes] = time.split(':').map(Number);
      const minutesFromMidnight = hours * 60 + minutes;

      newEnd.startOf('day').add(minutesFromMidnight, 'minutes');

      return {
        ...prev,
        end: newEnd.toDate()
      };
    });
  };

  const handleSelectEvent = (event) => {
    setNewEvent({
      ...event,
      color: event?.color ? event.color : ""
    });
    setSelectedEvent(event);
    setShowForm(true);
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };

    const goToCurrent = () => {
      toolbar.onNavigate('TODAY');
    };

    const handleRefresh = () => {
      fetchCalendar();
    };

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={handleRefresh}>Actualiser</button>
          <button type="button" onClick={goToBack}>{messages.previous}</button>
          <button type="button" onClick={goToCurrent}>{messages.today}</button>
          <button type="button" onClick={goToNext}>{messages.next}</button>
        </span>
        <span className="rbc-toolbar-label custom-toolbar-label">{toolbar.label}</span>
        <span className="rbc-btn-group">
          {toolbar.views.map(view => (
            <button
              key={view}
              type="button"
              onClick={() => toolbar.onView(view)}
              className={view === toolbar.view ? 'rbc-active' : ''}
            >
              {messages[view]}
            </button>
          ))}
        </span>
      </div>
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({
      ...newEvent,
      [name]: value
    });
  };

  const handleAddEvent = async () => {
    setShowForm(false);
    setIsLoading(true);
    const formatedNewEvent = {
      ...newEvent,
      start: format(newEvent.start, "yyyy-MM-dd HH:mm"),
      end: format(newEvent.end, "yyyy-MM-dd HH:mm")
    };

    if (selectedEvent) {
      await updateEvent(formatedNewEvent);
    } else {
      await addEvent(formatedNewEvent);
    }
    await fetchCalendar()
    setNewEvent({
      id_user: userId,
      ndossier: null,
      lot: null,
      title: "",
      start: "",
      end: "",
      color: "#4caf50",
      description: ""
    });
    setSelectedEvent(null);
  };

  const handleSelectSlot = ({ start, end }) => {
    const adjustedStart = moment(start).startOf('day').toDate();
    const adjustedEnd = moment(start).endOf('day').toDate();
    setNewEvent({
      ...newEvent,
      id_user: userId,
      start: adjustedStart,
      end: adjustedEnd
    });
    setShowForm(true);
  };

  const handleDeleteEvent = async () => {
    setOpenDelDialog(false);
    await deleteEvent(selectedEvent.id);
    await fetchCalendar();
    setNewEvent({
      id_user: userId,
      ndossier: null,
      lot: null,
      title: '',
      start: '',
      end: '',
      color: "#4caf50",
      description: ''
    });
    setSelectedEvent(null);
  };

  const handleCloseDialog = () => {
    setShowForm(false);
    setNewEvent({
      id_user: userId,
      ndossier: null,
      lot: null,
      title: '',
      start: '',
      end: '',
      color: "#4caf50",
      description: ''
    });
    setSelectedEvent(null);
  };

  const handleCloseOpenDelDialog = () => {
    setOpenDelDialog(false);
    setNewEvent({
      id_user: userId,
      ndossier: null,
      lot: null,
      title: '',
      start: '',
      end: '',
      color: "#4caf50",
      description: ''
    });
    setSelectedEvent(null);
  }

  const events = [...eventsGoogleAgenda, ...eventsErpAgenda].sort((a, b) => new Date(a.start) - new Date(b.start));

  return (
    <div className="agenda" style={{ height: '90vh' }}>
      {isLoading && <LoadingOverlay />}
      <Header />
      <h1 className="title">
        Agenda
      </h1>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={events}
        style={{
          height: '100vh',
          backgroundColor: 'transparent',
        }}
        messages={messages}
        eventPropGetter={(event) => ({
          style: { backgroundColor: event.color }
        })}
        dayPropGetter={(date) => ({
          style: {
            backgroundColor: moment(date).isSame(moment(), 'day') ? '#d5ffd5' : 'white'
          }
        })}
        components={{
          toolbar: (toolbarProps) => (
            <CustomToolbar {...toolbarProps} fetchCalendar={fetchCalendarRef.current} />
          ),
        }}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onNavigate={handleNavigate}
        date={currentDate}
        selectable
      />
      <Dialog open={showForm} onClose={handleCloseDialog}>
        <DialogTitle style={{ textAlign: 'center' }}>{(selectedEvent && !selectedEvent?.id) ? "EVENEMENT" : selectedEvent ? "MODIFIER L'EVENEMENT" : "AJOUTER UN EVENEMENT"}</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            label="Titre"
            value={newEvent.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            disabled={(selectedEvent && !selectedEvent?.id) ? true : false}
          />
          {((selectedEvent && selectedEvent?.id) || !selectedEvent) &&
            <>
              <FormControl fullWidth margin="normal" style={{ width: '150px' }}>
                <InputLabel id="folder-select-label">Dossier</InputLabel>
                <Select
                  labelId="folder-select-label"
                  id="ndossier"
                  name="ndossier"
                  value={newEvent?.ndossier || ''}
                  label="Dossier"
                  onChange={handleInputChange}
                  disabled={(selectedEvent && !selectedEvent?.id) ? true : false}
                >
                  <MenuItem key={'ndossier-null'} value={null}></MenuItem>
                  {dossiers.map(({ iddossier, ndossier }) => (
                    <MenuItem key={iddossier} value={ndossier}>
                      {ndossier}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl fullWidth margin="normal" style={{ width: '150px' }}>
                <InputLabel id="folder-select-label">Lot</InputLabel>
                <Select
                  labelId="folder-select-label-lot"
                  id="lot"
                  name="lot"
                  value={newEvent?.lot || ''}
                  label="Lot"
                  onChange={handleInputChange}
                  disabled={(selectedEvent && !selectedEvent?.id) ? true : false}
                >
                  <MenuItem key={'lot-null'} value={null}></MenuItem>
                  {lotList.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
            </>}
          <TextField
            name="date"
            label="Rendez-vous"
            type="date"
            value={moment(newEvent.start).format('YYYY-MM-DD')}
            onChange={handleDateChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '150px' }}
            disabled={(selectedEvent && !selectedEvent?.id) ? true : false}
          />
          <TextField
            name="startTime"
            label="Début"
            type="time"
            value={new Date(newEvent.start).toLocaleTimeString('fr-FR', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}
            onChange={handleStartTimeChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            style={textFieldStyle}
            disabled={(selectedEvent && !selectedEvent?.id) ? true : false}
          />
          <TextField
            name="endTime"
            label="Fin"
            type="time"
            value={new Date(newEvent.end).toLocaleTimeString('fr-FR', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}
            onChange={handleEndTimeChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            style={textFieldStyle}
            disabled={(selectedEvent && !selectedEvent?.id) ? true : false}
          /><br />
          {(selectedEvent === null || selectedEvent === undefined || selectedEvent?.id !== undefined) && (
            <TextField
              name="color"
              label="Couleur"
              type="color"
              value={newEvent?.color || "#4caf50"}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              style={{ width: '50px' }}
            />
          )}
          <TextField
            name="description"
            label="Description"
            value={newEvent.description}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            disabled={(selectedEvent && !selectedEvent?.id) ? true : false}
          />
        </DialogContent>
        <DialogActions>
          {selectedEvent ? selectedEvent?.id ?
            <Button variant="contained" onClick={handleAddEvent} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Modifier
            </Button> :
            null :
            <Button variant="contained" onClick={handleAddEvent} style={{ color: "white", backgroundColor: !newEvent?.ndossier ? "#ccc" : "#FF7F50" }} disabled={!newEvent?.ndossier}>
              Ajouter
            </Button>}
          {selectedEvent && selectedEvent?.id ?
            <Button
              variant="contained"
              onClick={() => {
                setShowForm(false);
                setOpenDelDialog(true);
              }}
              style={{ color: "white", backgroundColor: "#FF0000" }}
            >
              Supprimer
            </Button> : null}
          <Button variant="contained" onClick={handleCloseDialog} style={{ color: "white", backgroundColor: "#F5B039" }}>
            {selectedEvent ? selectedEvent?.id ? "Annuler" : "ok" : "Annuler"}
          </Button>
        </DialogActions>
      </Dialog>
      <YesNoDialog
        isOpen={openDelDialog}
        title={"SUPPRESSION DE L'EVENEMENT"}
        question={`Êtes-vous sûr de vouloir supprimer l'évènement ?`}
        handleYesClick={handleDeleteEvent}
        handleClose={handleCloseOpenDelDialog}
      />
    </div>
  );
};

export default Agenda;
