import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getCoordinationMeasuresData, genPgcPDF, getPgcFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPGC from "../../NumDossPGC";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import { laborCodeScaffoldingCont, coactivityRisksTitles, paragraphsCoactivityRisksDescription } from "./Const"
import "../../../Styles/PGC/Page4.scss";

const initialCoactivityRisks = {
  nature: "",
  type: "",
  realise: ""
};

const PgcPage4 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.pgc.index);
  const pgcValue = useSelector((state) => state.pgc.pgcValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [laborCodeScaffolding, setLaborCodeScaffolding] = useState(laborCodeScaffoldingCont);

  const [coactivityRisksDescription, setCoactivityRisksDescription] = useState("");
  const [coactivityRisks, setCoactivityRisks] = useState([initialCoactivityRisks]);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!dossierValue) return
    loadData()

  }, [pgcValue]);

  const loadData = async () => {
    if (!pgcValue) return resetData();
    setIsLoading(true);
    const responseCoordinationMeasuresData = await getCoordinationMeasuresData(pgcValue);

    if (responseCoordinationMeasuresData) {
      const laborCodeScaffoldingLoaded = responseCoordinationMeasuresData.labor_code_scaffolding ? JSON.parse(responseCoordinationMeasuresData.labor_code_scaffolding) : laborCodeScaffoldingCont;
      const coactivityRisksDescriptionLoaded = responseCoordinationMeasuresData.coactivity_risks_description;
      const coactivityRisksLoaded = responseCoordinationMeasuresData.coactivity_risks ? JSON.parse(responseCoordinationMeasuresData.coactivity_risks) : [initialCoactivityRisks];

      unstable_batchedUpdates(() => {
        setLaborCodeScaffolding(laborCodeScaffoldingLoaded);
        setCoactivityRisksDescription(coactivityRisksDescriptionLoaded);
        setCoactivityRisks(coactivityRisksLoaded);
        setIsLoading(false);
      });
    } else {
      unstable_batchedUpdates(() => {
        resetData();
        setIsLoading(false);
      });
    }
  };

  const resetData = async () => {
    setLaborCodeScaffolding(laborCodeScaffoldingCont);
    setCoactivityRisksDescription('');
    setCoactivityRisks([initialCoactivityRisks]);
  }

  const handleUpdateLaborCodeScaffolding = (event, index) => {
    const { value } = event.target;
    const list = [...laborCodeScaffolding];
    list[index] = {
      ...list[index],
      article: value
    };
    setLaborCodeScaffolding(list);
  }

  const handleUpdateCoactivityRisks = (event, index) => {
    const { value } = event.target;
    const list = [...coactivityRisks];

    if (value === "") {
      list[index] = initialCoactivityRisks
    } else list[index] = coactivityRisksTitles.find(item => item.nature === value);
    setCoactivityRisks(list);
  }

  const handleAddRisk = () => {
    setCoactivityRisks(prevState => [...prevState, initialCoactivityRisks])
  }

  const handleDeleteRisk = (index) => {
    if (coactivityRisks.length === 1) {
      setCoactivityRisks([initialCoactivityRisks])
    } else setCoactivityRisks(prevState => prevState.filter((item, i) => i !== index))
  }

  const handleSaveData = async () => {
    setDisableButton(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        pgcValue,
        laborCodeScaffolding: JSON.stringify(laborCodeScaffolding),
        coactivityRisksDescription,
        coactivityRisks: JSON.stringify(coactivityRisks)
      }),
    };
    try {
      const response = await fetch("/api/pgc/updateCoordinationMeasuresData", requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDisableButton(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPgcPDF(pgcValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`PGC`));
  }, [pgcValue]);

  const prevPage = () => {
    history.push("/pgc/page3");
  }

  const nextPage = () => {
    history.push("/pgc/page5");
  }

  return (
    <div className="pgc-page4">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Plan Général de Coordination (Mesures De Coordination)</h1>
      <NumDossPGC />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={2}><strong>Articles code du travail Echafaudage</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title" key={"titles"}>
              <td className="custom-cell-label"><strong>Type de protection provisoire ou définitive préconisée et localisation</strong></td>
              <td className="custom-cell-label"><strong>Article code du travail</strong></td>
            </tr>
            {laborCodeScaffolding.map((item, index) => (
              <tr className="custom-cell" key={index}>
                <td>{item.name}</td>
                <td><input type="text" value={item.article} onChange={(event) => handleUpdateLaborCodeScaffolding(event, index)} /></td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={4}><strong>Risques liés à la coactivité T.C.E</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td><span>Description</span></td>
              <td colSpan={3}>
                <select value={paragraphsCoactivityRisksDescription.indexOf(coactivityRisksDescription)} onChange={(e) => setCoactivityRisksDescription(paragraphsCoactivityRisksDescription[e.target.value])}>
                  <option value={0}></option>
                  <option value={1}>Paragraphe 1</option>
                  <option value={2}>Paragraphe 2</option>
                </select>
              </td>
            </tr>
            <tr className="custom-cell" style={{ textAlign: "center" }}>
              <td colSpan={4}><textarea value={coactivityRisksDescription} onChange={(e) => setCoactivityRisksDescription(e.target.value)}></textarea></td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" style={{ width: "200px" }}><strong>Nature du risque</strong></td>
              <td className="custom-cell-label" style={{ width: "390px" }}><strong>Type de protection provisoire préconisée</strong></td>
              <td className="custom-cell-label" style={{ width: "140px" }}><strong>Réalisée par le lot</strong></td>
              <td className="custom-cell-label"></td>
            </tr>
            {coactivityRisks.map((item, index) => {
              const filteredCoactivityRisksTitles = coactivityRisksTitles.filter(el =>
                !coactivityRisks.some(coactivityRisk => coactivityRisk.nature === el.nature && coactivityRisk !== item)
              );
              return (
                <tr className="custom-cell" key={index}>
                  <td>
                    <select value={item.nature} onChange={(e) => handleUpdateCoactivityRisks(e, index)} style={{ width: "200px" }} >
                      <option value={''}></option>
                      {filteredCoactivityRisksTitles.map((itemDes, indexDes) => (
                        <option value={itemDes.nature} key={indexDes}>{itemDes.nature}</option>
                      ))}
                    </select>
                  </td>
                  <td style={{ paddingRight: "5px" }}>{item.type}</td>
                  <td style={{ width: "163px" }}>
                    <input
                      type="text"
                      value={item.realise}
                      onChange={(e) => setCoactivityRisks(prevState => {
                        const updatedRisks = [...prevState];
                        updatedRisks[index] = { ...updatedRisks[index], realise: e.target.value };
                        return updatedRisks;
                      })}
                    />
                  </td>
                  <td>
                    <IconButton
                      style={{ margin: '0 5px 0 -5px' }}
                      aria-label="delete"
                      size="small"
                      className="delete"
                      disabled={coactivityRisks.length === 1 && coactivityRisks.every((item) => JSON.stringify(item) === JSON.stringify(initialCoactivityRisks))}
                      onClick={() => handleDeleteRisk(index)}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
            {coactivityRisks.length < 30 && <tr>
              <td className="add-risk" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddRisk}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>}
          </tbody>
        </table>

      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !pgcValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !pgcValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !pgcValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !pgcValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !pgcValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default PgcPage4;