import React from 'react';
import "../../Styles/Mail/createMail.scss"
import Header from '../Header/Header';
import { connect } from "react-redux";
import ConfirmMail from './ConfirmMail';
import MailParams from './MailParams';
import GenPdf from '../GenPdf';
import InformationDialog from '../InformationDialog';

class CreateMail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sender: "",
            password: "",
            to: "",
            cc: "",
            cci: "",
            subject: "",
            text: "",
            loading: false,
            file: null,
            pdf: false,
            // message: "",
            filename: "",
            infoMessage: "",
            disableButton: false,
            openDialog: false,
            openInfoDialog: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.showPDF = this.showPDF.bind(this);
        this.registerMailText = this.registerMailText.bind(this);
        this.setText = this.setText.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.closeDoc = this.closeDoc.bind(this);
    }
    async componentDidMount() {
        const { user, moInfos, dossierValue, dossierInfos, fileName, title } = this.props
        // const userMail = user[0].email
        const vicUrl = window.location.href.includes('vic')
        const rjUrl = window.location.href.includes('rj')

        if (user.map(user => user.email)[0] !== undefined) {
            // this.setState({ sender: user.map(user => user.email)[0], filename: fileName, cc: (userMail == "coordogec@coordogec.fr" ? "jeannette.wonje@coordogec.fr" : "coordogec@coordogec.fr") })
            this.setState({ sender: user.map(user => user.email)[0], filename: fileName, cc: "coordogec@coordogec.fr, jeannette.wonje@coordogec.fr" })
        }
        if (moInfos) {
            this.setState({ to: await this.checkContainsContractor(moInfos.mail), subject: `DOSSIER ${dossierValue.toString().split("").join("-")} : ${dossierInfos.operation} ${vicUrl ? fileName : rjUrl ? (title + " N°" + fileName.split("_")[2]) : title}` })
        }
    }
    componentDidUpdate(prevProps) {
        const { dossierValue, user, title, dossierInfos, fileName } = this.props
        const url = window.location.href.includes('vic')

        if (user.length !== prevProps.user.length) {
            this.setState({ sender: user.map(user => user.email)[0] })
        }
        if (title !== prevProps.title) {
            this.setState({ subject: `DOSSIER ${dossierValue.toString().split("").join("-")} : ${dossierInfos.operation} ${url ? fileName : title}` })
        }
        if (this.props.fileName !== prevProps.fileName) {
            this.setState({ filename: fileName })
        }
    }

    checkContainsContractor = async (mail) => {
        let newMail = mail

        const url = window.location.href.includes('vic')
        if (!url) return newMail

        const { dossierValue, lotValue, vic } = this.props
        let currentVic = vic.filter(vic => vic.ndossier == dossierValue && vic.lots == lotValue)
        if (currentVic.length > 0) {
            const idrespchan = currentVic[0].id_respchan
            currentVic = currentVic[0].travaux
            currentVic = JSON.parse(currentVic)
            let contractorCompany = currentVic.nom_soustraitant
            newMail += `, ${await this.getMailContractor(contractorCompany, idrespchan)}`
        }
        return newMail
    }

    getMailContractor = async (company, idrespchan) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", Accept: "application/json" },
            body: JSON.stringify({ company, idrespchan }),
        };
        return await fetch("/api/mail/getMailContractor", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                return data
            })
            .catch((err) => {
                console.log(err);
            });
    }

    extractEmails(inputString) {
        const emailRegex = /[\w.-]+@[\w-]+(?:\.[\w-]{2,})+/g;
        const emails = inputString.match(emailRegex);

        if (emails) {
            return emails.join(", ");
        } else {
            return "";
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    /**
     * Envoie le mail aux personnes concernées avec un fichier en pièce jointe ou non
     */
    async handleSubmit() {
        this.setState({ disableButton: true });
        var formData = new FormData()
        const { type } = this.props.match.params;
        let blob = await fetch(this.props.attachment).then(r => r.blob());
        const toFormated = this.extractEmails(this.state.to)
        const ccFormated = this.extractEmails(this.state.cc)
        const cciFormated = this.extractEmails(this.state.cci)

        const emailContent = `
            <div style="font-family: Aptos, Arial, sans-serif; font-size: 14px; line-height: 1.6;">
                ${this.state.text.replace(/\n/g, '<br>')}
            </div>
        `;
        formData.append("typefile", this.props.typeFile)
        formData.append("sender", this.state.sender)
        formData.append("password", this.state.password)
        formData.append("to", toFormated.split(",").map(email => email.trim()))
        formData.append("cc", ccFormated.split(",").map(email => email.trim()))
        formData.append("cci", cciFormated.split(",").map(email => email.trim()))
        formData.append("subject", this.state.subject)
        formData.append("text", emailContent)
        formData.append("filename", this.state.filename)
        formData.append("type_notif", type)
        formData.append("attachment", blob)
        const requestOptions = {
            method: 'POST',
            body: formData
        };

        this.setState({ to: toFormated, cc: ccFormated, cci: cciFormated })
        fetch(`/api/mail/sendMail`, requestOptions)
            .then(response => response.text())
            .then(result => {
                this.setState({
                    infoMessage: result === 'OK' ? 'Votre mail a bien été envoyé avec succes' : 'Une erreur est survenue lors de l\'envoi du mail',
                    text: result === 'OK' ? '' : this.state.text,
                    openInfoDialog: true,
                    disableButton: false
                })
            })
            .catch(error => console.log('error', error));
    }
    async showPDF() {
        let blob = await fetch(this.props.attachment).then(res => res.blob())
        if (blob) {
            this.openDoc()
            this.setState({ file: blob })
        }
    }
    /**
     * Enregistre le texte inscrit par l'utilisateur dans la base de données 
     * afin qu'il puisse être réutilisable en fonction du document à envoyer 
     */
    registerMailText() {
        let mail_message = {}
        const { type } = this.props.match.params;
        mail_message.user = this.props.user.map(user => user.email)[0]
        if (/facture/g.test(this.props.location.pathname)) mail_message.type_doc = "facture"
        if (/devis/g.test(this.props.location.pathname)) mail_message.type_doc = "devis"
        if (/vic/g.test(this.props.location.pathname)) mail_message.type_doc = "vic"
        else mail_message.type_doc = ""
        mail_message.text = this.state.text
        // this.setState({ message: this.state.text })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify(mail_message)
        };
        fetch("/api/registerMailtext", requestOptions)
    }
    setText() {
        this.setState({ text: this.state.message })
    }
    setOpen(open) {
        this.setState({ openDialog: open })
    }
    handleOpenDialog() {
        this.setOpen(true)
    }
    handleCloseDialog() {
        this.setOpen(false)
    }
    openDoc() {
        this.setState({ pdf: true })
    }
    closeDoc() {
        this.setState({ pdf: false })
    }

    handleClose() {
        this.setState({ openInfoDialog: false })
    }

    render() {

        const { user, title } = this.props
        
        return (
            <div className='send-mail'>
                <Header />
                <h1 className='title'>Envoi de {title}</h1>
                <div className="mail-submit">
                    <MailParams
                        sender={this.state.sender}
                        password={this.state.password}
                        to={this.state.to}
                        cc={this.state.cc}
                        cci={this.state.cci}
                        subject={this.state.subject}
                        text={this.state.text}
                        filename={this.state.filename}
                        disableButton={this.state.disableButton}
                        user={this.props.user}
                        handleChange={this.handleChange.bind(this)}
                        handleOpenDialog={this.handleOpenDialog}
                        showPDF={this.showPDF}
                    />
                </div>
                <GenPdf file={this.state.file} isOpen={this.state.pdf} closeDoc={this.closeDoc} />
                <ConfirmMail open={this.state.openDialog} onConfirm={this.handleSubmit} setOpen={this.setOpen} getTo={this.state.to} getSubject={this.state.subject} getText={this.state.text} getSender={user.map(user => { return user.email })} />
                <InformationDialog
                    isOpen={this.state.openInfoDialog}
                    title={'INFORMATION'}
                    information={this.state.infoMessage}
                    handleClose={this.handleClose.bind(this)}
                />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    user: state.user.items,
    notifs: state.notif.items,
    attachment: state.email.file,
    fileName: state.email.filename,
    typeFile: state.email.typefile,
    title: state.email.title,
    moInfos: state.contact.moInfos,
    dossierValue: state.devis.dossierValue,
    dossierInfos: state.dossier.item,
    lotValue: state.vic.lotValue,
    vic: state.vic.items,
});
export default connect(mapStateToProps)(CreateMail)
