import React, { useState, useCallback, useMemo } from 'react';
import { IconButton, Menu, MenuItem, Select } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";

const FirstYear = ({
  displayedYears,
  startYear,
  setStartYear,
  appointments,
  lotList,
  scheduleItemsFirstYear,
  itemConst,
  deleteItemFirstYear,
  setScheduleItemsFirstYear
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);

  const handleClick = useCallback((event, rowIndex, cellKey) => {
    setAnchorEl(event.currentTarget);
    setSelectedCell({ rowIndex, cellKey });
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedCell(null);
  }, []);

  const handleSelectLotCompany = useCallback((lot, company) => {
    if (selectedCell) {
      const { rowIndex, cellKey } = selectedCell;
      setScheduleItemsFirstYear(prevItems => {
        const updatedItems = [...prevItems];
        const lotWithCompany = company ? `${lot} (${company})` : lot;
        updatedItems[rowIndex] = { ...updatedItems[rowIndex], [cellKey]: lotWithCompany };
        return updatedItems;
      });
    }
    handleClose();
  }, [selectedCell, setScheduleItemsFirstYear, handleClose]);

  const addNewItem = useCallback(() => {
    setScheduleItemsFirstYear(prevItems => [...prevItems, itemConst]);
  }, [setScheduleItemsFirstYear, itemConst]);

  const handleYearChange = useCallback((event) => {
    setStartYear(event.target.value);
  }, [setStartYear]);

  const yearOptions = useMemo(() => {
    const years = [];
    for (let i = -5; i <= 5; i++) {
      years.push(startYear + i);
    }
    return years;
  }, [startYear]);

  const lotCompanyList = useMemo(() => {
    const list = [];
    lotList.forEach(lot => {
      const companiesForLot = appointments.filter(app => app.lot === lot).map(app => app.company);
      if (companiesForLot.length === 0) {
        list.push({ lot, company: null });
      } else {
        companiesForLot.forEach(company => {
          list.push({ lot, company });
        });
      }
    });
    return list;
  }, [lotList, appointments]);

  const renderHeaderRows = useMemo(() => (
    <>
      <tr className="custom-cell-thead">
        <td className="custom-cell-label" colSpan={4}><strong>LOT</strong></td>
        <td className="custom-cell-label" colSpan={4}><strong>DUREE EN MOIS</strong></td>
        <td className="custom-cell-label" colSpan={4}><strong>PREVISION CALENDRIER SUR {displayedYears * 12} MOIS</strong></td>
      </tr>
      <tr className="custom-cell-thead">
        <td className="custom-cell-label" colSpan={12} style={{ padding: "3px" }}>
          <Select
            value={startYear}
            onChange={handleYearChange}
            style={{ 
              width: '100px', 
              color: '#fff', 
              fontSize: '22px',
              marginLeft: '30px',
              fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif` 
            }}
          >
            {yearOptions.map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </td>
      </tr>
      <tr className="custom-cell-thead">
        <td className="custom-cell-label" colSpan={6}><strong>PREMIER SEMESTRE</strong></td>
        <td className="custom-cell-label" colSpan={6}><strong>DEUXIEME SEMESTRE</strong></td>
      </tr>
    </>
  ), [displayedYears, startYear, handleYearChange, yearOptions]);

  const renderFooterRows = useMemo(() => (
    <>
      <tr className="custom-cell-item">
        <td className="custom-cell-label" colSpan={6} style={{ backgroundColor: "#ffcc9f", padding: "10px" }}>SEQUENCE SEMESTRIELLE DE JANVIER A JUIN</td>
        <td className="custom-cell-label" colSpan={6} style={{ backgroundColor: "#ffcc9f", padding: "10px" }}>SEQUENCE SEMESTRIELLE DE JUILLET A DECEMBRE</td>
      </tr>
      <tr className="custom-cell-item">
        {[...Array(12)].map((_, i) => (
          <td className="custom-cell-label" key={`month-${i}`} style={{ backgroundColor: "#E8E5E2", padding: "10px" }}>M{i + 1}</td>
        ))}
      </tr>
      <tr className="custom-cell-item">
        <td colSpan={12} style={{ textAlign: 'left', backgroundColor: 'transparent' }}>
          <IconButton
            aria-label="add"
            style={{ backgroundColor: "transparent" }}
            onClick={addNewItem}
          >
            <Add fontSize="small" />
          </IconButton>
        </td>
      </tr>
    </>
  ), [addNewItem]);

  return (
    <table className="schedule-table">
      <thead>
        {renderHeaderRows}
      </thead>
      <tbody>
        {scheduleItemsFirstYear.map((item, i) => (
          <tr className="custom-cell-item" key={`item-${i}`}>
            {Object.entries(item).map(([key, value]) => (
              key !== 'id' && (
                <td
                  className="custom-cell-label"
                  key={key}
                  onClick={(e) => handleClick(e, i, key)}
                >
                  {value}
                </td>
              )
            ))}
            <td>
              <IconButton
                aria-label="delete"
                size="small"
                className="delete"
                onClick={() => deleteItemFirstYear(i)}
                title="Supprimer"
              >
                <Delete />
              </IconButton>
            </td>
          </tr>
        ))}
        {renderFooterRows}
      </tbody>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelectLotCompany('', null)} style={{ color: 'red' }}>
          Supprimer
        </MenuItem>
        {lotCompanyList.map((item, index) => (
          <MenuItem key={`lot-company-${index}`} onClick={() => handleSelectLotCompany(item.lot, item.company)}>
            {item.company ? `${item.lot} (${item.company})` : item.lot}
          </MenuItem>
        ))}
      </Menu>
    </table>
  );
};

export default React.memo(FirstYear);