import React, { useState, useEffect } from 'react';
import { ELEMENT_CONFIGS, CONSTRUCTION_ELEMENTS } from './Const';
import { dataUtils } from './dataStructureUtils';
import '../../../Styles/PreDIUO/ConstructionElements.scss';

const ConstructionElementTable = ({ isVisible, title, onInputChange, elementData = {} }) => {
  if (!isVisible) return null;

  const config = ELEMENT_CONFIGS[title];
  if (!config) return null;

  if (config.type === 'text') {
    return (
      <div className={`construction-elements__tables-container ${isVisible ? 'visible' : ''}`}>
        <h3 className="construction-elements__tables-title">{title}</h3>
        <div
          className="construction-elements__text-content"
          dangerouslySetInnerHTML={{
            __html: config.content
              .replace(/·/g, '<span style="font-size: 2em; vertical-align: middle; margin-right: 10px;">·</span>')
              .replace(/\*\*\*(.*?)\*\*\*/g, '<strong>$1</strong>')
          }}
          style={{ whiteSpace: 'pre-line' }}
        />
      </div>
    );
  }
  
  return (
    <div className={`construction-elements__tables-container ${isVisible ? 'visible' : ''}`}>
      <h3 className="construction-elements__tables-title">{title}</h3>

      {config.quantities && title !== 'Permis feu' && title !== 'Bruits et vibrations' && (
        <table className="table table--quantities">
          <thead className="table__header">
            <tr>
              {config.quantities.headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {(elementData.quantities || []).map((row) => (
              <tr key={row.rowIndex}>
                {row.values.map((cell) => (
                  <td key={cell.columnIndex}>
                    <input
                      type="text"
                      value={cell.value || ''}
                      onChange={(e) => onInputChange(title, 'quantities', row.rowIndex, cell.columnIndex, e.target.value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {config.informations && (
        <table className="table">
          <thead className="table__header">
            <tr>
              {config.informations.headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {(elementData.informations || []).map((row) => (
              <tr key={row.rowIndex}>
                <td>{row.text}</td>
                <td>
                  <input
                    type="text"
                    value={row.value || ''}
                    onChange={(e) => onInputChange(title, 'informations', row.rowIndex, 1, e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const ConstructionElements = ({ onDataChange, constructionData }) => {
  const [formData, setFormData] = useState({
    selectedElements: Object.fromEntries(CONSTRUCTION_ELEMENTS.map(element => [element, false])),
    elementsData: {}
  });

  useEffect(() => {
    if (constructionData) {
      const formattedConstructionData = {
        selectedElements: {
          ...Object.fromEntries(CONSTRUCTION_ELEMENTS.map(element => [element, false])),
          ...constructionData.selectedElements
        },
        elementsData: constructionData.elementsData || {}
      };
      setFormData(formattedConstructionData);
    }
  }, [constructionData]);

  useEffect(() => {
    onDataChange(formData);
  }, [formData, onDataChange]);

  const handleCheckboxChange = (element) => {
    setFormData(prev => {
      const newSelectedElements = {
        ...prev.selectedElements,
        [element]: !prev.selectedElements[element]
      };

      let newElementsData = { ...prev.elementsData };

      if (newSelectedElements[element]) {
        const config = ELEMENT_CONFIGS[element];
        newElementsData[element] = dataUtils.initializeElementData(config);
      } else {
        delete newElementsData[element];
      }

      return {
        selectedElements: newSelectedElements,
        elementsData: newElementsData
      };
    });
  };

  const handleInputChange = (element, tableType, rowIndex, columnIndex, value) => {
    setFormData(prev => {
      const elementData = prev.elementsData[element] ||
        dataUtils.initializeElementData(ELEMENT_CONFIGS[element]);

      const updatedElementData = dataUtils.updateElementValue(
        elementData,
        tableType,
        rowIndex,
        columnIndex,
        value
      );

      return {
        ...prev,
        elementsData: {
          ...prev.elementsData,
          [element]: updatedElementData
        }
      };
    });
  };

  return (
    <div className="construction-elements">
      <h2 className="construction-elements__title">Éléments de construction</h2>

      <div className="construction-elements__checklist">
        {CONSTRUCTION_ELEMENTS.map((element) => (
          <div key={element} className="construction-elements__checklist-item">
            <input
              type="checkbox"
              id={element}
              checked={formData.selectedElements[element]}
              onChange={() => handleCheckboxChange(element)}
            />
            <label
              htmlFor={element}
              className="construction-elements__label"
            >
              {element}
            </label>
          </div>
        ))}
      </div>

      <div className="construction-elements__tables">
        {CONSTRUCTION_ELEMENTS.map((element) => (
          <ConstructionElementTable
            key={element}
            title={element}
            isVisible={formData.selectedElements[element]}
            onInputChange={handleInputChange}
            elementData={formData.elementsData[element]}
          />
        ))}
      </div>
    </div>
  );
};

export default ConstructionElements;