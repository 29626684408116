import React from 'react';

const EmailTemplate = ({ content, title }) => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f4f4', margin: 0, padding: 0, borderRadius: '8px' }}>
      <div style={{
        maxWidth: '600px', margin: '0 auto', backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
      }}>
        {/* Header */}
        <div style={{ textAlign: 'center', padding: '20px 0', backgroundColor: '#ff7543', color: 'white', borderRadius: '10px 10px 0 0' }}>
          <h1>{title}</h1>
        </div>

        {/* Dynamic Body Content */}
        <div style={{ padding: '20px' }}>
          {content}
        </div>

        {/* Footer */}
        <table style={{ width: '100%', padding: '20px', backgroundColor: '#f4f4f4', borderRadius: '0 0 10px 10px' }}>
          <tbody>
            <tr>
              <td style={{ fontSize: '14px', color: '#333333' }}>
                Jean-Marie SAINT-GERMAIN<br />
                Directeur de l'agence<br />
                4, RUE DES ARTISANS<br />
                93160 NOISY-LE-GRAND<br />
                RCS BOBIGNY B 524 291 333<br />
                Tel : +33 1 43 03 63 17<br />
                Port : +33 6 80 68 14 82<br />
                <a href="http://www.coordogec.com" target="_blank">http://www.coordogec.com</a>
              </td>
              <td align="right">
                <img src="https://www.coordogec.fr/wp-content/themes/coordogec/assets/images/coordogec.jpg" alt="Logo Coordogec" style={{ width: '100px', height: '100px' }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default EmailTemplate;
